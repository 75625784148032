<template>
  <va-card :title="$t('reports.resume.region')">
    <template v-slot:actions>
      <actions
        crud-links="reports"
        :actions="actions"
        :action-data="{}"
      >
        <template v-slot:before>
          <va-badge
            :color="report.approval === 1 ? 'success' : 'danger'"
            v-show="report.approval === 1 || report.approval === -1"
          >
            {{ $t(approvalLabel(report.approval)) }}
          </va-badge>
        </template>
        <template v-if="report.id != null && currentUser.can('Proyections', 'monthly')">
          <va-popover
            :message="$t('tables.actions.export', {format: 'Excel'})"
            placement="left"
          >
            <va-button
              flat
              small
              color="success"
              icon="fa fa-file-excel-o"
              @click.prevent="prepareDownload()"
            />
          </va-popover>
        </template>
      </actions>
    </template>
    <tabs-container :tabs="tabs">
      <template v-slot:reports>
        <local-table
          class="table-hover table-striped full-table"
          crud-links="countryReports"
          :columns="resumeFields"
          :data="tableData.resume"
          :editable="edit"
          :loading="loading"
          :top-buttons="[]"
          :row-style-class="totalRowClass"
        />
      </template>
      <template v-slot:testimonies>
        <local-table
          class="table-hover table-striped full-table"
          :columns="testimoniesFields"
          :data="tableData.testimonies"
          :loading="loading"
          :top-buttons="[]"
        />
      </template>
      <template v-slot:assignments>
        <div class="row">
          <div class="flex xs12">
            <h5>{{ $t('reports.resume.total_assignment')({ assignment: report.totalAssignment || 0 }) }}</h5>
          </div>
          <div class="flex xs12">
            <local-table
              class="table-hover table-striped full-table"
              :columns="assignmentsFields"
              :data="tableData.assignments"
              :loading="loading"
              :top-buttons="[]"
              :row-style-class="totalRowClass"
            />
          </div>
        </div>
      </template>
    </tabs-container>
  </va-card>
</template>
<script>
import { mapGetters } from 'vuex'
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')

export default {
  name: 'report-summary-table',
  components: {
    TabsContainer,
    LocalTable,
    Actions,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    report: {
      type: Object,
      required: false,
      default: function () { return {} },
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    tabs () {
      return [
        { title: this.$t('reports.resume.reports'), name: 'reports' },
        { title: this.$t('reports.resume.testimonies'), name: 'testimonies' },
        { title: this.$t('reports.resume.assignments'), name: 'assignments' },
      ]
    },
    canReview () {
      return this.currentUser.can('RegionReports', 'approve')
    },
    resumeFields () {
      return [
        {
          name: 'country',
          title: this.$t('tables.headings.country'),
          sortField: 'country',
        },
        {
          name: 'date',
          title: this.$t('tables.headings.date'),
          callback: this.dateLabel,
        },
        {
          name: 'presentations',
          title: this.$t('tables.headings.presentations'),
        },
        {
          name: 'audience',
          title: this.$t('tables.headings.assistance'),
        },
        {
          name: 'newcomers',
          title: this.$t('tables.headings.newcomers'),
        },
        {
          name: 'regulars',
          title: this.$t('tables.headings.regulars'),
        },
        {
          name: 'preaching_points',
          title: this.$t('tables.headings.preaching_points'),
        },
        {
          name: 'assistance',
          title: this.$t('tables.headings.preaching_assistance'),
        },
        {
          name: 'completed',
          title: this.$t('tables.headings.complete_report'),
          callback: this.completedLabel,
        },
        {
          name: 'actions',
          visible: this.edit,
          dataClass: this.totalActionsClass,
        },
      ]
    },
    testimoniesFields () {
      return [
        {
          name: 'country',
          title: this.$t('tables.headings.country'),
        },
        {
          name: 'code',
          title: this.$t('tables.headings.team'),
        },
        {
          name: 'original',
          title: this.$t('tables.headings.testimony_original'),
          dataClass: this.hasTestimonyEsClass,
        },
        {
          name: 'english',
          title: this.$t('tables.headings.testimony_english'),
          dataClass: this.hasTestimonyEnClass,
        },
      ]
    },
    assignmentsFields () {
      return [
        {
          name: 'country',
          title: this.$t('tables.headings.country'),
        },
        {
          name: 'region_assignment',
          title: this.$t('tables.headings.region_assignment'),
          callback: this.assignmentsLabel,
        },
        {
          name: 'country_assignment',
          title: this.$t('tables.headings.country_assignment'),
          callback: this.assignmentsLabel,
        },
        {
          name: 'team_assignment',
          title: this.$t('tables.headings.team_assignment'),
          callback: this.assignmentsLabel,
        },
        {
          name: 'total',
          title: this.$t('tables.headings.total'),
          callback: this.assignmentsLabel,
        },
      ]
    },
  },
  data () {
    return {
      actions: ['index'],
      tableData: {
        resume: [],
        testimonies: [],
        assignments: [],
      },
    }
  },
  watch: {
    async report (val) {
      if (val.resume) {
        this.tableData.resume = val.resume
      }
      if (val.testimonies) {
        this.tableData.testimonies = val.testimonies
      }
      if (val.assignments) {
        this.tableData.assignments = val.assignments
      }
    },
  },
  methods: {
    approvalLabel (approval) {
      let a = 'tables.status.unreviewed'
      switch (approval) {
        case 1: a = 'tables.status.approved'
          break
        case -1: a = 'tables.status.rejected'
          break
      }

      return this.$t(a)
    },
    completedLabel (approval) {
      if (approval === '') return ''
      const a = approval ? 'layout.yes' : 'layout.no'
      return this.$t(a)
    },
    dateLabel (date) {
      if (date === '') return ''
      return this.$date.format(date, 'MMMM - yyyy')
    },
    assignmentsLabel (assignment) {
      return '$' + assignment
    },
    hasTestimonyEsClass (row) {
      return this.hasTestimonyClass(row.original)
    },
    hasTestimonyEnClass (row) {
      return this.hasTestimonyClass(row.english)
    },
    hasTestimonyClass (testimony) {
      return testimony && testimony.length > 0 ? 'has-testimony' : 'non-testimony'
    },
    totalActionsClass (row) {
      return row.country === 'TOTAL' ? 'total-actions' : ''
    },
    totalRowClass (row) {
      return row.code === 'TOTAL' ? 'total-row' : ''
    },
    prepareDownload () {
      this.$emit('export')
    },
  },
}
</script>
<style lang="scss">
.report-title {
  margin-bottom: 0;
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

.full-table {
  width: 100%;
}

.has-testimony {
  background-color: rgba(0, 255, 0, 0.25);
}

.non-testimony {
  background-color: rgba(255, 0, 0, 0.25);
}

.total-row {
  font-size: bold;

  span {
    color: black;
  }
}

.total-actions {
  span {
    display: none;
  }
}
</style>
